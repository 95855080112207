<template>
  <codemirror
    v-model="code"
    placeholder="Uw tekst komt hier..."
    :indent-with-tab="true"
    :extensions="extensions"
    :style="{ height: '300px' }"
    @ready="handleReady"
  />
  <CRow class="mt-4">
    <CCol v-for="group in groups" :key="group.name">
      <h4>{{ group.name }}</h4>
      <CRow v-for="i in group.rows" :key="i">
        <CCol @click="insertText(group.variables[i * 2 - 2].insertValue)">{{ group.variables[i * 2 - 2].displayValue }}</CCol>
        <CCol @click="insertText(group.variables[i * 2 - 1]?.insertValue ?? '')">{{ group.variables[i * 2 - 1]?.displayValue }}</CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { defineComponent, ref, shallowRef, watch, toRefs, computed } from 'vue';
import { Codemirror } from 'vue-codemirror';
import { xml } from '@codemirror/lang-xml';

export default defineComponent({
  name: 'AdvancedEditor',
  components: {
    Codemirror
  },
  props: {
    modelValue: {
      required: false
    },
    metadata: {
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue: modelValue, metadata: metadata } = toRefs(props);

    const groups = computed(() => {
      const g = [];

      for (let prop in metadata.value) {
        g.push({
          name: prop,
          variables: metadata.value[prop],
          rows: Math.ceil(metadata.value[prop].length / 2)
        });
      }

      return g;
    });
    const code = ref('');

    watch(
      modelValue,
      () => {
        code.value = modelValue.value ?? '';
      },
      { immediate: true }
    );

    watch(code, () => {
      emit('update:modelValue', code.value);
    });

    const extensions = [xml()];

    // Codemirror EditorView instance ref
    const editor = shallowRef();
    const handleReady = (payload) => {
      editor.value = payload.view;
    };

    const insertText = (text) => {
      const ranges = editor.value.state.selection.ranges;
      const cursor = ranges[0].anchor;

      editor.value.dispatch({
        changes: { from: cursor, insert: text }
      });
    };

    return {
      code,
      extensions,
      handleReady,
      insertText,
      groups
    };
  }
});
</script>