import CrudService from '@/services/CrudService';

const namespaced = true;

const state = {};

const mutations = {
  SET_ROWS(state, { payload, identifier }) {
    state[identifier].rows = payload;
  },

  SET_METADATA(state, { payload, identifier }) {
    state[identifier].metadata = payload;
  },

  SET_NEW_GRID_VIEW(state, identifier) {
    state[identifier] = {
      rows: [],
      metadata: {}
    };
  },

  REMOVE_ROW(state, { id, identifier }) {
    const index = state[identifier].rows.findIndex((row) => row.id === id);
    state[identifier].rows.splice(index, 1);
  }
};

const actions = {
  getRows({ commit }, { endpoint, identifier }) {
    CrudService.getDataByQuery(endpoint, 'includeChildren', true)
      .then((response) => {
        if (response.data.success) {
          commit('SET_ROWS', { payload: response.data.value, identifier });
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  deleteById({ commit }, { endpoint, id, identifier }) {
    CrudService.deleteById(endpoint, id)
      .then((response) => {
        if (response.data.success) {
          commit('REMOVE_ROW', { id, identifier });
        }

        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De geselecteerde rij is succesvol verwijderd' }, { root: true });
      })
      .catch((error) => {
        commit('ADD_TOAST_ERROR', error, { root: true });
        throw error;
      });
  }
};

const dynamicgridview = {
  state,
  mutations,
  actions,
  namespaced
};

export default dynamicgridview;
