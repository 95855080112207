import { getErrorMessage } from '../../helpers/formHelper';

const namespaced = true;

const state = {};

const mutations = {
  SET_FIELD_VALUES(state, { payload, identifier }) {
    state[identifier].fieldValues = payload;
  },

  SET_METADATA(state, { payload, identifier }) {
    payload.properties.forEach((p) => {
      if (p.enumValues?.length) {
        p.options = [];
        p.enumValues.forEach((val, index) => {
          p.options.push({
            displayText: val,
            key: index
          });
        });
      }
    });
    state[identifier].metadata = payload;
  },

  SET_VALIDATION_ERROR(state, { error, propertyName, identifier }) {
    state[identifier].validationErrors.push({
      error,
      propertyName
    });
  },

  CLEAR_VALIDATION_ERROR(state, { propertyName, identifier }) {
    for (let i = 0; i < state[identifier].validationErrors.length; i++) {
      if (state[identifier].validationErrors[i].propertyName == propertyName) {
        state[identifier].validationErrors.splice(i, 1);
      }
    }
  },

  SET_LOADING_STATUS(state, { loadingStatus, identifier }) {
    state[identifier].submitLoadingStatus = loadingStatus;
  },

  SET_NEW_FORM(state, identifier) {
    state[identifier] = {
      fieldValues: {},
      metadata: undefined,
      validationErrors: [],
      submitLoadingStatus: false
    };
  }
};

const actions = {
  getError({ commit }, { property, value, id, identifier, fieldValues }) {
    const propertyName = property.variableName;
    commit('CLEAR_VALIDATION_ERROR', { propertyName, identifier });
    const error = getErrorMessage(property, value, id, fieldValues);
    if (error) {
      commit('SET_VALIDATION_ERROR', { error, propertyName, identifier });
    }
  }
};

const dynamicform = {
  state,
  mutations,
  actions,
  namespaced
};

export default dynamicform;
