import ApiClient from './ApiClient';

export default {
  getToolsInUit(data) {
    return ApiClient().post('/wissellijst/toolsinout', data);
  },

  create(data) {
    return ApiClient().post('/wissellijst/create', data);
  },

  save(data) {
    return ApiClient().post('/wissellijst/save', data);
  },

  getAll() {
    return ApiClient().get('/wissellijst');
  },

  get(id) {
    return ApiClient().get(`/wissellijst/${id}`);
  },

  getHistory(machineId) {
    return ApiClient().get(`/wissellijst/History/${machineId}`);
  },

  update(regels) {
    return ApiClient().put('/wissellijst/exchange', regels);
  },

  finish(data) {
    return ApiClient().post('/wissellijst/finish', data);
  }
};
