<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px">Machine Magazijnen - {{ machineNaam }}</strong>
          <CButton
            @click="toVersletenTools"
            :disabled="!gridRows.filter((row) => row.versleten || row.exchange).length"
            color="primary"
            class="btn-md"
            style="float: right"
          >
            Doorgaan
          </CButton>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            name="MMagazijnen"
            :metadata="magazijnenMetadata"
            :data="magazijnenData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import CrudService from '@/services/CrudService.js';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';

export default {
  name: 'TabletMachineMagazijnen',
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    if (!store.state.dynamicgrid['MMagazijnen']) {
      store.commit('dynamicgrid/SET_NEW_GRID', 'MMagazijnen');
      store.dispatch('tabletmachinemagazijnen/getMagazijnenMetadata');
    }

    store.dispatch('tabletmachinemagazijnen/getMagazijnenData', machineId.value);

    const gridRows = computed(() => store.state.dynamicgrid['MMagazijnen'].rows);

    const machineNaam = computed(() => store.state.tabletmachinemagazijnen.machineNaam);

    const magazijnenSelectedRow = computed(() => {
      if (store.state.dynamicgrid['MMagazijnen'].selectedRows.length > 0) {
        return store.state.dynamicgrid['MMagazijnen'].selectedRows[0];
      }
      return undefined;
    });

    const magazijnenMetadata = computed(() => store.state.tabletmachinemagazijnen.magazijnenMetadata);
    const magazijnenData = computed(() => store.state.tabletmachinemagazijnen.magazijnenData);
    const versletenWerkelijkeToolIds = computed(() => store.state.tabletmachinemagazijnen.versletenWerkelijkeToolIds);

    function toVersletenTools() {
      const versletenIds = gridRows.value.filter((row) => row.versleten && row.werkelijkeToolId).map((row) => row.werkelijkeToolId);
      const werkendeIds = gridRows.value.filter((row) => !row.versleten && row.werkelijkeToolId).map((row) => row.werkelijkeToolId);
      const editIds = gridRows.value.filter((row) => row.exchange).map((row) => parseInt(row.id));

      CrudService.postData('werkelijketools/versleten', { machineId: machineId.value, versletenIds, werkendeIds })
        .then((response) => {
          store.commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
          if (response.data.success) {
            store.commit('tabletmachinemagazijnen/SET_VERSLETEN_TOOL_IDS', versletenIds);
            router.push({
              name: 'tabletversletentools',
              params: {
                machineId: machineId.value,
                editIds
              }
            });
          }
        })
        .catch(() => {
          store.commit('ADD_TOAST_ERROR', 'Uw wijzigingen konden niet worden verwerkt.', { root: true });
        });
    }

    function goToWerkvloer() {
      router.push({
        name: 'werkvloer'
      });
    }

    return {
      magazijnenMetadata,
      magazijnenData,
      magazijnenSelectedRow,
      versletenWerkelijkeToolIds,
      gridRows,
      toVersletenTools,
      machineNaam,
      goToWerkvloer,
      cilArrowLeft
    };
  }
};
</script>