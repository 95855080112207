import CrudService from '../../services/CrudService';
import WissellijstService from '../../services/WissellijstService';

const namespaced = true;

const state = {
  wissellijstRegelsData: [],
  wissellijstRegelsMetadata: {},
  wissellijstenMetadata: {},
  wissellijstenData: [],
  wissellijstenSaved: false
};

const mutations = {
  SET_WISSELLIJSTEN_REGELS_DATA(state, payload) {
    state.wissellijstenRegelsData = payload;
  },

  SET_WISSELLIJSTEN_REGELS_METADATA(state, payload) {
    state.wissellijstenRegelsMetadata = payload;
  },

  SET_WISSELLIJSTEN_METADATA(state, payload) {
    state.wissellijstenMetadata = payload;
  },

  SET_WISSELLIJSTEN_DATA(state, payload) {
    state.wissellijstenData = payload;
  },

  REMOVE_ROW(state, id) {
    const index = state.wissellijstenData.findIndex((wissellijst) => wissellijst.id === id);
    state.wissellijstenData.splice(index, 1);
  }
};

const actions = {
  getWissellijstenMetadata({ commit }) {
    CrudService.getMetadataByName('wissellijst', 'grid')
      .then((response) => {
        commit('SET_WISSELLIJSTEN_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getWissellijstenData({ commit }) {
    WissellijstService.getAll()
      .then((response) => {
        if (response.data.success) {
          commit('SET_WISSELLIJSTEN_DATA', response.data.value);
        }

        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getWissellijstRegelsMetadata({ commit }) {
    CrudService.getMetadataByName('wissellijstregels', 'grid')
      .then((response) => {
        commit('SET_WISSELLIJSTEN_REGELS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  deleteWissellijst({ commit, rootState }, id) {
    CrudService.deleteById('wissellijst', id)
      .then((response) => {
        if (response.data.success) {
          commit('REMOVE_ROW', id);
          commit('SET_WISSELLIJSTEN_REGELS_DATA', []);

          commit('ADD_TOAST_SUCCESS', 'De wissellijst is succesvol verwijderd.', { root: true });

          if (rootState?.toollijstenview?.machines?.length) {
            const machine = rootState.toollijstenview.machines.filter((x) => x.wissellijsten.some((w) => w.id === id))[0];
            if (machine) {
              const indexOfWissellijst = machine.wissellijsten.findIndex((w) => w.id === id);
              if (indexOfWissellijst >= 0) {
                machine.wissellijsten.splice(indexOfWissellijst, 1);
              }
            }
          }
        }

        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('ADD_TOAST_ERROR', error, { root: true });
        throw error;
      });
  }
};

const wissellijstenview = {
  state,
  mutations,
  actions,
  namespaced
};

export default wissellijstenview;
