import ApiClient from './ApiClient';

export default {
  getModelNames(type) {
    return ApiClient().get(`/frontend/${type}/components`);
  },

  getMetadataByName(name, type) {
    return ApiClient().get(`/frontend/${type}/components/${name}`);
  },

  getGridData(endpoint) {
    return ApiClient().get(`/${endpoint}`);
  },

  getDataById(endpoint, id) {
    return ApiClient().get(`/${endpoint}/${id}`);
  },

  getDataByQuery(endpoint, queryName, queryValue) {
    return ApiClient().get(`/${endpoint}?${queryName}=${queryValue}`);
  },

  getDataByQueries(endpoint, queries) {
    let queryString = queries.map((q) => `${q.queryName}=${q.queryValue}`);
    queryString = queryString.join('&');
    return ApiClient().get(`/${endpoint}?${queryString}`);
  },

  getMany(endpoint, payload) {
    return ApiClient().post(`/${endpoint}`, payload);
  },

  postData(endpoint, data) {
    return ApiClient().post(`/${endpoint}`, data);
  },

  putData(endpoint, id, data) {
    return ApiClient().put(`/${endpoint}/${id}`, data);
  },

  deleteById(endpoint, id) {
    return ApiClient().delete(`/${endpoint}/${id}`);
  },

  deleteMany(endpoint, data) {
    return ApiClient().delete(`/${endpoint}`, { data });
  },

  deleteByQueries(endpoint, queries) {
    let queryString = queries.map((q) => `${q.queryName}=${q.queryValue}`);
    queryString = queryString.join('&');
    return ApiClient().delete(`/${endpoint}?${queryString}`);
  },

  uploadFile(endpoint, file) {
    const formData = new FormData();
    formData.append('file', file);
    return ApiClient().post(endpoint, formData);
  }
};
