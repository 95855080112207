const { default: CrudService } = require('../services/CrudService');
import moment from 'moment';

export async function loadOptionsAsync(properties) {
  let extraData = [];
  for (const property of properties) {
    if (property.endpoint) {
      let filter = extraData.filter((d) => d.endpoint === property.endpoint);
      const data = filter?.length ? filter[0].data : (await CrudService.getGridData(property.endpoint)).data.value;

      if (!filter?.length) {
        extraData.push({ endpoint: property.endpoint, data });
      }

      property.usedValues = data;
      if (property.displayProperties?.length) {
        property.options = data.map((obj) => {
          let values = [];
          property.displayProperties.forEach((element) => {
            const split = element.split('.');
            let actualPath = obj;
            split.forEach((el) => {
              if (!actualPath[el]) return;
              actualPath = actualPath[el];
            });
            values.push(actualPath);
          });
          return { displayText: values.join('-'), key: obj[property.keyProperty] };
        });
      }
    }
  }
}

export function prepareData(data) {
  for (let property in data) {
    if (moment(data[property], 'YYYY-MM-DDTHH:mm:ss', true).isValid() === true) {
      data[property] = data[property].split('T')[0];
    }
  }
}

export function createUrl(config, data = undefined, requiredVariable = undefined) {
  function getQueryValue(param, data, requiredVariable) {
    if (param.useRequiredVariable) return requiredVariable;
    if (param.variableName && data) {
      let obj = data;

      const split = param.variableName.split('.');
      for (let s of split) {
        obj = obj[s];
        if (!obj) return undefined;
      }

      return obj;
    }
    return param.value;
  }

  function getQueryUrl(config, data, requiredVariable) {
    var queryParameters = config.queryParameters.length
      ? config.queryParameters.map((param) => `${param.key}=${getQueryValue(param, data, requiredVariable)}`).join('&')
      : '';

    if (queryParameters.includes('undefined')) return undefined;
    return `${config.endpoint}${queryParameters != '' ? '?' : ''}${queryParameters}`;
  }

  function getPathUrl(config, data) {
    let url = config.endpoint;
    const parameter = config.pathParameter;

    if (parameter) {
      let subObj = data;
      const params = parameter.split('.');
      for (let p of params) {
        subObj = subObj[p];
        if (!subObj) return undefined;
      }

      url += `/${subObj}`;
    }

    return url;
  }

  if (config.queryParameters) {
    return getQueryUrl(config, data, requiredVariable);
  } else {
    return getPathUrl(config, data);
  }
}
