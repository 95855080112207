import CrudService from '../../services/CrudService';
import { loadOptionsAsync } from '@/helpers/apiHelper.js';

const namespaced = true;

const state = {};

const mutations = {
  SET_FIELD_VALUES(state, { payload, identifier }) {
    state[identifier].fieldValues = payload;
  },

  SET_METADATA(state, { payload, identifier }) {
    state[identifier].metadata = payload;
  },

  SET_NEW_TOOLLIJSTEN_FORM(state, identifier) {
    state[identifier] = {
      fieldValues: {},
      metadata: {}
    };
  }
};

const actions = {
  getMetadata({ commit }, { identifier, isUpdate }) {
    CrudService.getMetadataByName('toollijsten', 'form')
      .then(async (response) => {
        // Filter out the ones that are not editable only if it is add
        response.data.properties = response.data.properties.filter((prop) => isUpdate || prop.editable);

        // Ensures we are not loading the same endpoint multiple times
        await loadOptionsAsync(response.data.properties);
        response.data.stretchInputFields = true;
        commit('SET_METADATA', { payload: response.data, identifier });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getFieldValues({ commit }, { id, identifier }) {
    CrudService.getDataByQuery(`toollijsten/${id}`, 'includeChildren', false)
      .then((response) => {
        if (response.data.success) {
          commit('SET_FIELD_VALUES', { payload: response.data.value, identifier });
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const toollijstencrud = {
  state,
  mutations,
  actions,
  namespaced
};

export default toollijstencrud;
