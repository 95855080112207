import CrudService from '../../services/CrudService';
import { loadOptionsAsync, prepareData } from '../../helpers/apiHelper';

const namespaced = true;

const state = {
  toolsMetadata: {},
  componentMetadata: {},
  componentsMetadata: {},
  werkelijkeToolsMetadata: {},
  werkelijkeToolsFormMetadata: {}
};

const mutations = {
  SET_COMPONENT_METADATA(state, payload) {
    state.componentMetadata = payload;
  },

  SET_COMPONENTS_METADATA(state, payload) {
    state.componentsMetadata = payload;
  },

  SET_COMPONENTS_DATA(state, { payload, identifier }) {
    state[identifier].componentsData = payload;
  },

  SET_TOOLS_METADATA(state, payload) {
    state.toolsMetadata = payload;
  },

  SET_TOOLS_DATA(state, { payload, identifier }) {
    state[identifier].toolsData = payload;
  },

  SET_WERKELIJKE_TOOLS_METADATA(state, payload) {
    payload.properties.push({
      variableName: 'printLabel',
      displayName: 'Print',
      pinned: true,
      displayType: 10
    });
    state.werkelijkeToolsMetadata = payload;
  },

  SET_WERKELIJKE_TOOLS_FORM_METADATA(state, payload) {
    state.werkelijkeToolsFormMetadata = payload;
  },

  SET_OLD_COMPONENTS_IN_TOOL(state, { payload, identifier }) {
    state[identifier].oldComponentsInTool = payload;
  },

  SET_CURRENT_COMPONENTS_IN_TOOL(state, { payload, identifier }) {
    state[identifier].currentComponentsInTool = payload;
  },

  SET_NEW_TOOLS_FORM(state, identifier) {
    state[identifier] = {
      toolsData: {
        werkelijkeTools: [],
        components: []
      },
      componentsData: [],
      oldComponentsInTool: [],
      currentComponentsInTool: []
    };
  }
};

const actions = {
  getToolsMetadata({ commit }) {
    CrudService.getMetadataByName('tools', 'form')
      .then(async (response) => {
        await loadOptionsAsync(response.data.properties);
        commit('SET_TOOLS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getComponentsMetadata({ commit }) {
    CrudService.getMetadataByName('components', 'grid')
      .then((response) => {
        commit('SET_COMPONENTS_METADATA', response.data);
        const props = JSON.parse(JSON.stringify(response.data));
        props.properties.push({
          variableName: 'aantal',
          displayName: 'Aantal',
          displayType: 1,
          pinned: true,
          editable: true
        });
        commit('SET_COMPONENT_METADATA', props);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getWerkelijkeToolsMetadata({ commit }) {
    CrudService.getMetadataByName('werkelijketools', 'grid')
      .then((response) => {
        response.data.properties = response.data.properties.filter((p) => p.variableName != 'tool');
        commit('SET_WERKELIJKE_TOOLS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getToolData({ commit }, { id, identifier }) {
    CrudService.getDataById('tools', id)
      .then(async (response) => {
        prepareData(response.data.value);
        const hasToollijstToolOrWerkelijkeTool = await CrudService.getDataByQuery('tools/restrictedit', 'toolId', id);
        response.data.value.restrictEdit = hasToollijstToolOrWerkelijkeTool.data.success && hasToollijstToolOrWerkelijkeTool.data.value;
        commit('SET_TOOLS_DATA', { identifier, payload: response.data.value });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getComponentsData({ commit }, identifier) {
    CrudService.getGridData('components')
      .then((response) => {
        prepareData(response.data.value);
        commit('SET_COMPONENTS_DATA', { identifier, payload: response.data.value });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getWerkelijkeToolsFormMetadata({ commit }, { isUpdate, machineGroep }) {
    CrudService.getMetadataByName('werkelijketools', 'form')
      .then(async (response) => {
        response.data.properties = response.data.properties.filter((p) => p.editable || isUpdate === true || isUpdate == 'true');

        const toolId = response.data.properties.filter((p) => p.variableName == 'toolId')[0];
        toolId.editable = false;
        let extraData = [];
        for (const property of response.data.properties) {
          if (property.endpoint) {
            let filter = extraData.filter((d) => d.endpoint === property.endpoint);
            let data;
            if (property.endpoint == 'tools') {
              data = filter?.length ? filter[0].data : (await CrudService.getGridData(property.endpoint)).data.value;
            } else {
              data = filter?.length ? filter[0].data : (await CrudService.getDataByQuery(property.endpoint, 'machineGroep', machineGroep)).data.value;
            }

            if (!filter?.length) {
              extraData.push({ endpoint: property.endpoint, data });
            }

            property.usedValues = data;
            if (property.displayProperties?.length) {
              property.options = data.map((obj) => {
                let values = [];
                property.displayProperties.forEach((element) => {
                  const split = element.split('.');
                  let actualPath = obj;
                  split.forEach((el) => {
                    if (!actualPath[el]) return;
                    actualPath = actualPath[el];
                  });
                  values.push(actualPath);
                });
                return { displayText: values.join('-'), key: obj[property.keyProperty] };
              });
            }
          }
        }
        commit('SET_WERKELIJKE_TOOLS_FORM_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  printLabels({ commit }, werkelijkeTools) {
    CrudService.postData(
      'werkelijketools/printlabels',
      werkelijkeTools.map((x) => x.id)
    )
      .then((response) => {
        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De labels worden geprint.' }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const toolscrud = {
  state,
  mutations,
  actions,
  namespaced
};

export default toolscrud;
