import CrudService from '../../services/CrudService';
import ToollijstService from '../../services/ToollijstService';

const namespaced = true;

const state = {
  toollijstenMetadata: [],
  toollijstenData: {},
  selectedToollijstId: -1,
  machineNaam: undefined
};

const mutations = {
  SET_TOOLLIJSTEN_METADATA(state, payload) {
    state.toollijstenMetadata = payload;
  },

  SET_TOOLLIJSTEN_DATA(state, payload) {
    state.toollijstenData = payload;
  },

  SET_SELECTED_TOOLLIJST_ID(state, payload) {
    state.selectedToollijstId = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  }
};

const actions = {
  getToollijstenMetadata({ commit }) {
    CrudService.getMetadataByName('toollijsten', 'grid')
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) =>
            p.variableName == 'toollijstNaam' ||
            p.variableName == 'klant' ||
            p.variableName == 'tekeningnummer' ||
            p.variableName == 'revisienummer' ||
            p.variableName == 'benaming' ||
            p.variableName == 'aanmaakdatum'
        );
        commit('SET_TOOLLIJSTEN_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getToollijstenData({ commit }, machineId) {
    ToollijstService.getToollijstenInMachine(machineId)
      .then((response) => {
        if (response.data.success) {
          commit(
            'SET_TOOLLIJSTEN_DATA',
            response.data.value.map((x) => x.toollijst)
          );
        }

        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getMachine({ commit }, machineId) {
    CrudService.getDataById('machines', machineId)
      .then((response) => {
        if (response.data.success) {
          commit('SET_MACHINE_NAAM', response.data.value.naam);
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const tabletmachinetoollijsten = {
  state,
  mutations,
  actions,
  namespaced
};

export default tabletmachinetoollijsten;
