<template>
  <!-- eslint-disable -->
  <v-select
    :options="config.options"
    v-if="config?.options?.length || (config && !config.displayTextIfNoOptions)"
    v-model="params.data[config.valuePropertyName]"
    @update:model-value="onOptionChanged"
    placeholder="Uw Keuze"
    :searchable="true"
    style="font-size: 12px"
    :disabled="!config?.options?.length && !params.data[config.valuePropertyName]"
    :clearable="false"
    :label="config.label"
    :reduce="(option) => option[config.key ?? config.label]"
  >
    <template #option="option">
      <span>{{ option[config.label] }}</span>
      <span v-if="option.machine?.nummer"> ({{ option.machine?.nummer }})</span>
    </template>
    <template #no-options>
      Geen opties beschikbaar.
    </template>
  </v-select>
  <span v-else>{{ config?.displayTextIfNoOptions ?? 'n.v.t.' }}</span>
</template>


<script>
import { defineComponent, toRefs, computed, watch } from 'vue';
import 'vue-select/dist/vue-select.css';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
  name: 'DropdownCellRenderer',
  props: {
    params: {
      required: false
    }
  },
  setup(props) {
    const { params: parameters } = toRefs(props);

    const columnName = computed(() => parameters.value.column.colId);
    const config = computed(() =>
      columnName.value && parameters.value.data.dropdownConfiguration?.length
        ? parameters.value.data.dropdownConfiguration.filter((x) => x.valuePropertyName == columnName.value)[0]
        : parameters.value.data.dropdownConfiguration
    );

    // const vModel = ref(parameters.value.data[config.valuePropertyName]);
    // watch(vModel, () => {
    //     let colId = parameters.value.column.colId;
    //     parameters.value.node.setDataValue(colId, vModel.value);
    // })

    watch(
      config,
      () => {
        if (config.value && !config.value?.options.length && config.value.useDefaultTextAsValue) {
          let colId = parameters.value.column.colId;
          parameters.value.node.setDataValue(colId, config.value.displayTextIfNoOptions);
        }
      },
      { immediate: true }
    );

    function onOptionChanged(option) {
      let colId = parameters.value.column.colId;
      parameters.value.node.setDataValue(colId, option);
    }
    function isPopup() {
      return true;
    }

    return {
      parameters,
      config,
      isPopup,
      onOptionChanged
    };
  }
});
</script>

<style>


</style>