<template>
  <CCard>
    <CCardHeader>
      <strong> Machines </strong>
      <CButton @click="goToGrid" class="btn btn-primary btn-sm" style="float: right">Annuleren</CButton>
      <CButton :disabled="validationErrors.length" @click="onSubmit" class="btn btn-primary btn-sm" style="float: right; margin-right: 4px"
        >Opslaan</CButton
      >
    </CCardHeader>
    <CRow>
      <CCol :xs="12" :lg="6">
        <CCard style="box-shadow: none">
          <CCardBody class="border border-0 pe-0">
            <dynamic-form class="mb-3" name="MFMachines" :metadata="metadata" :data="data" :isEdit="!!id" />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol :xs="12" :lg="6">
        <CCard class="me-3 mt-3 mb-3">
          <CCardHeader><strong>Magazijnen in machine</strong></CCardHeader>
          <CCardBody>
            <CButton color="primary" @click="addMagazijn" class="btn-sm mb-2">Toevoegen aan machine</CButton>
            &nbsp;
            <CButton color="primary" :disabled="selectedMachineMagazijn === undefined" @click.prevent="removeMagazijn" class="btn-sm mb-2"
              >Verwijderen uit machine</CButton
            >
            &nbsp;
            <div style="float: right">
              <CLabel style="vertical-align: top; position: relative" class="col-form-label">AI TmId</CLabel>
              &nbsp;
              <input style="width: 20px; height: 20px" type="checkbox" class="checkbox" v-model="autoIncrementTmId" />
            </div>
            <dynamic-grid
              @data-changed="onDataChanged"
              name="MFMachinemagazijnen"
              :metadata="magazijnenMetadata"
              :data="magazijnenData"
              :autoPagination="true"
            />
          </CCardBody>
        </CCard>
        <CCard class="me-3 mt-3 mb-3">
          <CCardHeader><strong>Beschikbare werkelijke tools</strong></CCardHeader>
          <CCardBody>
            <CButton
              color="primary"
              :disabled="selectedMachineMagazijn === undefined || selectedWerkelijkeTool?.id === undefined"
              @click="addWerkelijkeToolToMagazijn"
              class="btn-sm mb-2"
              >In magazijn zetten</CButton
            >
            <dynamic-grid name="MFWerkelijkeTools" :metadata="werkelijkeToolsMetadata" :data="availableWerkelijkeTools" :autoPagination="true" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import DynamicForm from '../dynamicform/DynamicForm.vue';
import DynamicGrid from '../dynamicgrid/DynamicGrid.vue';
import { computed, toRefs, watch, ref } from 'vue';
import { useStore } from 'vuex';
import CrudService from '../../services/CrudService';
import { useRouter } from 'vue-router';

export default {
  name: 'MachinesFormView',
  components: { DynamicForm, DynamicGrid },
  props: {
    id: {
      required: false
    }
  },
  setup(props) {
    const { id: id } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    store.commit('machinesformview/CLEAR_HISTORY');
    store.commit('dynamicform/SET_NEW_FORM', 'MFMachines');
    store.commit('dynamicgrid/SET_NEW_GRID', 'MFMachinemagazijnen');
    store.commit('dynamicgrid/SET_NEW_GRID', 'MFWerkelijkeTools');

    const metadata = computed(() => store.state.machinesformview.metadata);
    const magazijnenMetadata = computed(() => store.state.machinesformview.magazijnenMetadata);

    const availableWerkelijkeTools = computed(() => store.state.machinesformview.availableWerkelijkeTools);
    const werkelijkeToolsMetadata = computed(() => store.state.machinesformview.werkelijkeToolsMetadata);

    const data = computed(() => store.state.machinesformview.data);
    const magazijnenData = computed(() => store.state.machinesformview.data?.machineMagazijnen);
    // Can actually track the changed fields like tmId or checkbox
    const gridMachineMagazijnen = computed(() => store.state.dynamicgrid['MFMachinemagazijnen'].rows);

    const selectedMachineMagazijn = computed(() => store.state.dynamicgrid['MFMachinemagazijnen'].selectedRows[0]);
    const selectedWerkelijkeTool = computed(() => store.state.dynamicgrid['MFWerkelijkeTools'].selectedRows[0]);

    const validationErrors = computed(() => store.state.dynamicform['MFMachines'].validationErrors);
    const actualFormValues = computed(() => store.state.dynamicform['MFMachines'].fieldValues);

    const autoIncrementTmId = ref(true);

    store.dispatch('machinesformview/getMachinesMetadata', !!id.value);
    store.dispatch('machinesformview/getMagazijnenMetadata');
    store.dispatch('machinesformview/getAvailableWerkelijkeTools', id.value);
    store.dispatch('machinesformview/getWerkelijkeToolsMetadata');

    if (id.value) {
      store.dispatch('machinesformview/getMachine', id.value);
    }

    const gridApi = ref(undefined);
    function onDataChanged(params) {
      gridApi.value = params;
    }
    const onSubmit = async () => {
      const isUpdate = !!id.value;

      store.state.machinesformview.data.machineMagazijnen = gridMachineMagazijnen.value;
      const body = actualFormValues.value;
      body.machineMagazijnen = magazijnenData.value.map((x) => {
        x.werkelijkeTool = undefined;
        return x;
      });

      const updateOrAdd = () => (isUpdate ? CrudService.putData('machines', id.value, body) : CrudService.postData('machines', body));

      updateOrAdd()
        .then((response) => {
          if (isUpdate) {
            store.commit('ADD_TOAST_SUCCESS', 'Uw wijzigingen zijn opgeslagen.', { root: true });
          } else {
            store.commit('ADD_TOAST_SUCCESS', `Uw ${metadata.value.modelDisplayName} is succesvol toegevoegd.`, { root: true });
            id.value = response.id;
          }
          goToGrid();
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }));
    };

    function goToGrid() {
      router.push({
        name: 'dynamicgridview',
        params: {
          name: 'machines'
        }
      });
    }

    const actualTotalSlots = computed(() => store.state.dynamicform['MFMachines'].fieldValues.totalSlots);
    watch(actualTotalSlots, () => {
      const difference = actualTotalSlots.value - (magazijnenData.value?.length ?? 0);
      store.state.machinesformview.data.totalSlots = actualTotalSlots.value;
      if (difference < 0) {
        store.state.machinesformview.data.machineMagazijnen =
          actualTotalSlots.value == 0 ? [] : magazijnenData.value.slice(0, actualTotalSlots.value);
      } else {
        const newMagazijnen = [];
        const lastSuggestedTmId = magazijnenData.value?.length ? Math.max(...magazijnenData.value.map((o) => o.tmId ?? 0)) + 1 : 1;
        for (let i = 0; i < difference; i++) {
          // Id is only for the removing behavior (REMOVE_ROW in dynamicgrid.js uses the field 'id' and must therefore be created)
          newMagazijnen.push({ tmId: lastSuggestedTmId + i });
        }

        store.state.machinesformview.data.machineMagazijnen = magazijnenData.value.concat(newMagazijnen);
      }
    });

    // duplication because watching old and new data with deep true does not give differences in old and new data in the watch
    // this is a "bug" in Vue
    // This watch sets all tmId's + 1 + n higher than the last changed one for user experience
    const lastMachineMagazijnenData = ref([]);
    watch(
      gridMachineMagazijnen,
      () => {
        if (gridMachineMagazijnen.value?.length && autoIncrementTmId.value) {
          if (lastMachineMagazijnenData.value.length && gridMachineMagazijnen.value.length == lastMachineMagazijnenData.value.length) {
            for (let i = 0; i < lastMachineMagazijnenData.value.length; i++) {
              if (lastMachineMagazijnenData.value[i].tmId != gridMachineMagazijnen.value[i].tmId) {
                for (let x = i + 1; x < lastMachineMagazijnenData.value.length; x++) {
                  store.state.dynamicgrid['MFMachinemagazijnen'].rows[x].tmId = parseInt(gridMachineMagazijnen.value[i].tmId) + x - i;
                }
                break;
              }
            }
          }

          lastMachineMagazijnenData.value = JSON.parse(JSON.stringify(gridMachineMagazijnen.value));
        }
      },
      { deep: true }
    );

    function addMagazijn() {
      var nextSuggestedTmId = magazijnenData.value?.length ? Math.max(...magazijnenData.value.map((o) => o.tmId ?? 0)) + 1 : 1;
      store.state.machinesformview.data.machineMagazijnen.push({ tmId: nextSuggestedTmId });
      store.state.machinesformview.data.totalSlots = (store.state.machinesformview.data.totalSlots ?? 0) + 1;
    }

    function removeMagazijn() {
      if (!selectedMachineMagazijn.value.werkelijkeTool) {
        const index = store.state.machinesformview.data.machineMagazijnen.findIndex((row) => row.tmId === selectedMachineMagazijn.value.tmId);
        store.state.machinesformview.data.machineMagazijnen.splice(index, 1);

        store.state.dynamicgrid['MFMachinemagazijnen'].selectedRows = [];
        store.state.machinesformview.data.totalSlots = (store.state.machinesformview.data.totalSlots ?? 1) - 1;
      } else {
        const idToSelect = selectedMachineMagazijn.value.tmId;
        const werkelijkeToolToBePutBack = store.state.machinesformview.availableWerkelijkeToolsArchived.filter(
          (x) => x.id == selectedMachineMagazijn.value.werkelijkeToolId
        )[0];
        store.state.machinesformview.availableWerkelijkeTools.push(werkelijkeToolToBePutBack);
        selectedMachineMagazijn.value.werkelijkeTool = undefined;
        selectedMachineMagazijn.value.werkelijkeToolId = undefined;

        // time out required because when this click event is over it messes the selected row up
        setTimeout(() => {
          gridApi.value.api.forEachNode((node) => node.setSelected(idToSelect == node.data.tmId));
        }, 0);
      }
    }

    function addWerkelijkeToolToMagazijn() {
      const magazijn = store.state.machinesformview.data?.machineMagazijnen.filter((m) => m.tmId == selectedMachineMagazijn.value.tmId)[0];
      if (magazijn.werkelijkeTool && magazijn.werkelijkeTool.id != selectedWerkelijkeTool.value.id) {
        store.state.machinesformview.availableWerkelijkeTools.push(magazijn.werkelijkeTool);
      }
      magazijn.werkelijkeTool = selectedWerkelijkeTool.value;
      magazijn.werkelijkeToolId = selectedWerkelijkeTool.value.id;
      magazijn.standaard = true;

      const index = store.state.machinesformview.availableWerkelijkeTools.findIndex((row) => row.id == selectedWerkelijkeTool.value.id);
      store.state.machinesformview.availableWerkelijkeTools.splice(index, 1);
      store.state.dynamicgrid['MFWerkelijkeTools'].selectedRows = [];
    }

    return {
      metadata,
      magazijnenMetadata,
      data,
      magazijnenData,
      validationErrors,
      onSubmit,
      goToGrid,
      addMagazijn,
      selectedMachineMagazijn,
      selectedWerkelijkeTool,
      removeMagazijn,
      werkelijkeToolsMetadata,
      availableWerkelijkeTools,
      addWerkelijkeToolToMagazijn,
      autoIncrementTmId,
      onDataChanged
    };
  }
};
</script>